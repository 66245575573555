import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue'

const routes = [
  {
    path: '/',
    redirect: '/tabs/accueil'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'accueil'
      },
      {
        path: 'accueil',
        name: 'accueil',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'secteurs',
        name: 'secteurs',
        component: () => import('@/views/PageSecteurs.vue')
      },
      {
        path: 'secteurs/:id',
        name: 'ficheSecteur',
        component: () => import('@/views/PageDetailSecteur.vue'),
        props: true
      },
      {
        path: 'metiers',
        name: 'metiers',
        component: () => import('@/views/PageMetiers.vue')
      },
      {
        path: 'metiers/:id',
        name: 'ficheMetier',
        component: () => import('@/views/PageDetailMetier.vue'),
        props: true
      },
      {
        path: 'qualites',
        name: 'qualites',
        component: () => import('@/views/PageQualites.vue')
      },
      {
        path: 'qualites/:id',
        name: 'ficheQualite',
        component: () => import('@/views/PageDetailQualites.vue'),
        props: true
      },
      {
        path: 'favoris',
        name: 'favoris',
        component: () => import('@/views/PageFavoris.vue')
      },
      {
        path: 'carte',
        name: 'carte',
        component: () => import('@/views/PageCarte.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router

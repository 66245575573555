import { createStore } from 'vuex'
import secteurs from '@/data/secteurs.json'
import metiers from '@/data/metiers.json'
import qualites from '@/data/qualites.json'
import ilots from '@/data/ilots.json'

const favorisLS = (localStorage.getItem('metiersFavoris'))
  ?  JSON.parse(localStorage.getItem('metiersFavoris'))
  : []

export default createStore({
  state: {
    secteurs: secteurs,
    metiers: metiers,
    qualites: qualites,
    favoris: favorisLS,
    ilots: ilots,
    enChargement : false,
    carteMetierId: null,
    carteIlotId: null
  },
  mutations: {
    SET_CARTE_METIER (state, valeur) {
      state.carteMetierId = valeur
    },
    SET_CARTE_ILOT (state, valeur) {
      state.carteIlotId = valeur
    },
    SET_EN_CHARGEMENT (state, valeur) {
      state.enChargement = valeur
    },
    SET_FAVORIS (state, favorisArray) {
      state.favoris = favorisArray
      localStorage.setItem('metiersFavoris', JSON.stringify(state.favoris))
    },
    ADD_FAVORI (state, idMetier) {
      state.favoris.push(idMetier)
      localStorage.setItem('metiersFavoris', JSON.stringify(state.favoris))
    },
    REMOVE_FAVORI (state, idMetier) {
      let index = state.favoris.indexOf(idMetier)
      if (index > -1) {
        state.favoris.splice(index, 1)
        localStorage.setItem('metiersFavoris', JSON.stringify(state.favoris))
      }
    },
  },
  actions: {
    resetCarte ({ commit }) {
      commit('SET_CARTE_METIER', null)
      commit('SET_CARTE_ILOT', null)
    },
    setCarteMetier ({ commit }, idMetier) {
      commit('SET_CARTE_ILOT', null)
      commit('SET_CARTE_METIER', parseInt(idMetier))
    },
    setCarteIlot ({ commit }, idIlot) {
      commit('SET_CARTE_METIER', null)
      commit('SET_CARTE_ILOT', parseInt(idIlot))
    },
    setFavoris ({ commit }, favorisArray) {
      commit('SET_FAVORIS', favorisArray)
    },
    addFavori ({ commit, state}, idMetier) {
      idMetier = parseInt(idMetier)
      if (idMetier && !state.favoris.includes(idMetier)) {
        commit('ADD_FAVORI', idMetier)
      }
    },
    removeFavori ({ commit }, idMetier) {
      idMetier = parseInt(idMetier)
      commit('REMOVE_FAVORI', idMetier)
    },
    toggleFavori ({ commit, state }, idMetier) {
      idMetier = parseInt(idMetier)
      if (state.favoris.includes(idMetier)) {
        commit('REMOVE_FAVORI', idMetier)
      } else {
        commit('ADD_FAVORI', idMetier)
      }
    }
  },
  modules: {

  },
  getters: {
    metiers: state => state.metiers.sort((a, b) => a.nom.localeCompare(b.nom, 'fr')),
    carteMetierId: state => state.carteMetierId,
    carteIlotId: state => state.carteIlotId,
    metiersSansStandsInfo: (getters) => {
      return getters.metiers.filter(metier => metier.titre)
    },
    secteurs: state => state.secteurs.sort((a, b) => a.numero.localeCompare(b.numero, 'fr')),
    qualites: state => state.qualites.sort((a, b) => a.libelle.localeCompare(b.libelle, 'fr')),
    ilots: state => state.ilots.sort((a, b) => parseInt(a.numero) > parseInt(b.numero)),
    favoris: state => state.favoris,
    isFavori: (state) => (idMetier) => {
      idMetier = parseInt(idMetier)
      return state.favoris.includes(idMetier)
    },
    getSecteur: (state) => (id) => {
      return state.secteurs.find(secteur => secteur.id === parseInt(id))
    },
    getMetier: (state) => (id) => {
      return state.metiers.find(metier => metier.id === parseInt(id))
    },
    getMetierIlots: (getters) => (idIlot) => {
      return Object.values(getters.metiers).filter((metier) => {
        return metier.ilots.find(ilot => ilot.id === parseInt(idIlot))
      })
    },
    getQualite: (state) => (id) => {
      return state.qualites.find(qualite => qualite.id === parseInt(id))
    },
    getIlot: (state) => (id) => {
      return state.ilots.find(ilot => ilot.id === parseInt(id))
    },
    getMetiersFavoris: (state, getters) => {
      if (state.favoris) {
        return Object.values(getters.metiers).filter((metier) => {
          return state.favoris.includes(metier.id)
        })
      }

      return []
    },
    enChargement: state => state.enChargement,
  }
})

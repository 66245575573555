<template>
  <ion-page>
    <ion-tabs>
      <!-- Chargement des sous-pages -->
      <ion-router-outlet></ion-router-outlet>
      <!-- Onglets de navigation -->
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/tabs/accueil">
          <ion-icon class="puce-logo"
                    :src="require('@/assets/puce-logo.svg')"></ion-icon>
          <ion-label>Accueil</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="secteurs" href="/tabs/secteurs">
          <ion-icon :icon="gitNetworkSharp" />
          <ion-label>Secteurs</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="metiers" href="/tabs/metiers">
          <ion-icon :icon="constructSharp" />
          <ion-label>Métiers</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="qualites" href="/tabs/qualites">
          <ion-icon :icon="ribbonSharp" />
          <ion-label>Qualités</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="favoris" href="/tabs/favoris">
          <ion-icon :icon="starSharp" />
          <ion-label>Favoris</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="carte" href="/tabs/carte">
          <ion-icon :icon="mapSharp" />
          <ion-label>Carte</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import {
    gitNetworkSharp,
    ribbonSharp,
    starSharp,
    constructSharp,
    mapSharp
} from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      gitNetworkSharp,
      ribbonSharp,
      starSharp,
      constructSharp,
      mapSharp
    }
  }
}
</script>
